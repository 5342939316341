import { userKey } from "@/global";
export default {
  data() {
    return {
      nomeFantasia: "",
      id: {
        empresa: "",
        empresa_user: "",
        nome: "",
        usuario: "",
        admin: false,
        tipoAcesso: ""
      },
      configEmpresa: {}
    }
  },
  methods: {
    initVariaveis() {
      const json = localStorage.getItem(userKey);
      if (json === null){
        router.push('/login');
      }
      const userData = JSON.parse(json);
      this.id.empresa = userData.id_empresa;
      this.id.empresa_user = userData.id_empresa_user;
      this.nomeFantasia = userData.empresa.nm_fantasia;
      this.id.nome = userData.nm_nome;
      this.id.usuario = userData.hl_email;
      this.id.admin = userData.fl_admin === "1" ? true : false;
      this.id.tipoAcesso = userData.fl_tipo_acesso;
      this.configEmpresa = userData.plano.json_detalhes;
      //this.id.limite_users = userData.plano.nu_qtd_usuarios;
      //this.id.limite_rh_users = userData.plano.nu_qtd_usuarios_rh;
      //console.log('userData no Mixins ->> ', userData)
      //console.log('var id no Mixins ->> ', this.id)
    },
  },
  created() {
    this.initVariaveis();
  }
}
