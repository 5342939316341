<template>
  <base-nav
    v-model="showMenu"
    container-classes="container-fluid"
    class="navbar-horizontal navbar-main py-0"
    :class="{ 'bg-primary navbar-dark': type === 'default' }"
  >
    <template v-slot:brand>
      <div class="navbar-wrapper">
        <router-link class="navbar-brand" to="/">
          <h3 class="display-3 mb-0" style="color: white">MATCHJOB</h3>
          <!-- <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/MatchjobHiring-transparente.png" class="img-fluid" alt="MatchJob Hiring"> -->
          <!-- <img src="https://storagematchjobdefault.blob.core.windows.net/matchjob/icone-142x142.png" class="img-fluid" alt="MatchJob Hiring"> -->
        </router-link>
      </div>
    </template>
    <div class="navbar-collapse-header">
      <div class="row">
        <div class="col-6 collapse-brand">
          <router-link to="/">
            <h3 class="display-3 mb-0">MATCHJOB</h3>
          </router-link>
        </div>
        <div class="col-6 collapse-close">
          <button
            type="button"
            class="navbar-toggler"
            @click="showMenu = false"
          >
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </div>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center mr-md-auto">
      <li class="nav-item">
        <router-link to="/dashboard" class="nav-link">Home</router-link>
      </li>
      <li class="nav-item">
        <router-link to="/vagas" class="nav-link">Vagas</router-link>
      </li>
      <!-- <li class="nav-item">
        <router-link to="/#" class="nav-link">Candidatos</router-link>
      </li> -->
    </ul>
    <ul class="navbar-nav align-items-center ml-md-auto">

      <!-- Menu de Apps -->
      <base-dropdown
        menu-classes="dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right"
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link"
        icon="ni ni-ungroup"
      >
        <div class="row shortcuts px-4">
          <a href="#/vagas" class="col-4 shortcut-item">
            <span
              class="shortcut-media avatar rounded-circle bg-gradient-green"
            >
              <i class="ni ni-books"></i>
            </span>
            <small>Vagas</small>
          </a>
          <a href="#/testes" class="col-4 shortcut-item">
            <span class="shortcut-media avatar rounded-circle bg-gradient-info">
              <i class="fas fa-chart-bar"></i>
            </span>
            <small>Testes</small>
          </a>
          <a href="#/enderecos" class="col-4 shortcut-item">
            <span
              class="shortcut-media avatar rounded-circle bg-gradient-purple"
            >
              <i class="ni ni-pin-3"></i>
            </span>
            <small>Endereços</small>
          </a>
          <a href="#/usuarios" class="col-4 shortcut-item">
            <span
              class="shortcut-media avatar rounded-circle bg-gradient-yellow"
            >
              <i class="fas fa-users"></i>
            </span>
            <small>Usuários</small>
          </a>
          <a href="#/arquivos" class="col-4 shortcut-item" v-if="plano.nm_plano === '09300df44b9d20cc219b25abddc3346e'">
            <span class="shortcut-media avatar rounded-circle bg-gradient-red">
              <i class="far fa-file-pdf"></i>
            </span>
            <small>Arquivos</small>
          </a>
          <a href="#/mensagens" class="col-4 shortcut-item">
            <span
              class="shortcut-media avatar rounded-circle bg-gradient-orange"
            >
              <i class="ni ni-email-83"></i>
            </span>
            <small>Mensagens</small>
          </a>
        </div>
      </base-dropdown>
      <!-- Menu de Notificações -->
      <base-dropdown
        class="nav-item"
        tag="li"
        title-classes="nav-link"
        title-tag="a"
        icon="ni ni-bell-55"
        menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
      >
        <!-- Notificacoes - Dropdown header -->
        <div class="px-3 py-3">
          <h6 class="text-sm text-muted m-0">
            Você tem
            <strong class="text-primary">{{
              notificacoesUsuario.length
            }}</strong>
            notificações.
          </h6>
        </div>
        <!-- List group -->
        <div class="list-group list-group-flush">
          <div v-for="notif in notificacoesUsuario" :key="notif.id">
            <a
              :href="notif.link"
              class="list-group-item list-group-item-action"
            >
              <div class="row align-items-center">
                <div class="col-auto">
                  <!-- Avatar -->
                  <img
                    alt="Image placeholder"
                    src="https://storagematchjobdefault.blob.core.windows.net/matchjob/rouded-logo-50x50.png"
                    class="avatar rounded-circle"
                  />
                </div>
                <div class="col ml--2">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <h4 class="mb-0 text-sm">{{ notif.title }}</h4>
                    </div>
                    <div class="text-right text-muted">
                      <small>2 hrs ago</small>
                    </div>
                  </div>
                  <p class="text-sm mb-0">
                    {{ notif.body }}
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <!-- View all -->
        <a
          href="#!"
          class="dropdown-item text-center text-primary font-weight-bold py-3"
          >View all</a
        >
      </base-dropdown>
      <!-- Menu do Perfil -->
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="nav-link pr-0" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" :src="perfilUsuario.hl_foto" />
              </span>
              <!-- <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{
                  perfilUsuario.nm_nome
                }}</span>
              </div> -->
            </div>
          </a>
        </template>

        <div class="dropdown-header noti-title">
          <h6 class="text-overflow m-0">{{perfilUsuario.nm_nome}}<br>Plano - {{retornaNomePlano(configEmpresa.nm_plano)}}</h6>
        </div>
        <a href="#/meu-perfil" class="dropdown-item">
          <i class="ni ni-single-02"></i>
          <span>Meu perfil</span>
        </a>
        <a href="#/configuracoes" class="dropdown-item">
          <i class="ni ni-settings-gear-65"></i>
          <span>Configurações</span>
        </a>
        <!-- <a href="#!" class="dropdown-item">
          <i class="ni ni-calendar-grid-58"></i>
          <span>Atividades</span>
        </a>
        <a href="#!" class="dropdown-item">
          <i class="ni ni-support-16"></i>
          <span>Ajuda</span>
        </a> -->
        <div class="dropdown-divider"></div>
        <base-button class="dropdown-item" tag="a" @click="logout()">
          <i class="ni ni-user-run"></i>
          <span>Sair...</span>
        </base-button>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import defaultMatchjob from '../../mixins/defaultMatchjob';
import { db } from "../../firebase/config";
import { doc, getDoc, collection, getDocs, onSnapshot } from "firebase/firestore";
import { userKey } from "@/global"
import BaseNav from "@/components/Navbar/BaseNav";

export default {
  mixins: [defaultMatchjob],
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (blue) or light (gray)",
    },
    perfilUsuario: {
      type: Object,
    },
    notificacoesUsuario: {
      type: Array
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    plano() {
      return this.$store.state.empresa.plano;
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      notificacoes: [],
      planosEnv: [
        { value: process.env.VUE_APP_MATCHJOB_FREE, text: "Free" },
        { value: process.env.VUE_APP_MATCHJOB_BASICO, text: "Básico" },
        { value: process.env.VUE_APP_MATCHJOB_PROFISSIONAL, text: "Profissional" },
        { value: process.env.VUE_APP_MATCHJOB_ENTERPRISE, text: "Enterprise" }
      ]
    };
  },
  methods: {
    initValores() {
      /* this.planosEnv = [
        { value: process.env.VUE_APP_MATCHJOB_FREE, text: "Free" },
        { value: process.env.VUE_APP_MATCHJOB_BASICO, text: "Básico" },
        { value: process.env.VUE_APP_MATCHJOB_PROFISSIONAL, text: "Profissional" },
        { value: process.env.VUE_APP_MATCHJOB_ENTERPRISE, text: "Enterprise" }
      ] */
      //this.plano = this.propPlano;
    },
    retornaNomePlano(value) {
      if (value === undefined) {
        return '';
      } else {
        var plano = this.planosEnv.find(i => i.value === value);
        return plano.text;
      }
    },
    carregaNotificacoes() {
      const colRefNotif =
        "empresas/" +
        this.id.empresa +
        "/usuarios/" +
        this.id.usuario +
        "/notificacoes";
      const resNotif = collection(db, colRefNotif);
      const unsub = onSnapshot(resNotif, snapshot => {
        let res = [];
        snapshot.docs.forEach((doc) => {
          res.push({ ...doc.data(), id: doc.id });
          //console.log('res ->> ', res)
        });
        this.notificacoes = res;
      });
      //console.log("unsub ->> ", unsub);
      //console.log("notificacao ->> ", this.notificacoes);
    },
    logout() {
      localStorage.removeItem(userKey);
      localStorage.removeItem('nomeFantasia');
      localStorage.removeItem('access_token');
      localStorage.removeItem('session_end_datetime');
      this.$store.dispatch('resetStatesLogoff');
      this.$router.push({ name: "Login" });
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
  },
  mounted() {
    //this.initValores();
    //this.carregaNotificacoes();
  }
};
</script>
