import Vue from "vue";
import axios from "axios";
import router from '../routes/router';
import { userKey } from "@/global";

Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios.create({
      baseURL: "https://api.empresa.matchjob.com.br/",
      //baseURL: "http://api.empresa.matchjob.local/",
      //baseURL: process.env.VUE_APP_BASE_URL,
      headers: {
        //'Access-Control-Allow-Origin': '*',
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      }
    });
    Vue.prototype.$http.interceptors.request.use(config => {
      let token = localStorage.getItem("access_token");
      //console.log("axios.js -> valor de token carregado -> ", token)
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
        //console.log("axios.js ->> var config -> ", config)
      }
      return config
    }, error => Promise.reject(error))

    Vue.prototype.$http.interceptors.response.use(res => {
        return res
    }, error => {
      if (error.response.status === 401) {
        console.log("axios.js -> Recebido erro 401 ->>");
        localStorage.removeItem(userKey);
        localStorage.removeItem('nomeFantasia');
        localStorage.removeItem('access_token');
        router.push('/login');
      }
      return Promise.reject(error)
    })
  }
});

const success = res => res;
const error = err => {
  if (401 === err.response.status) {
    window.location = "/";
  } else {
    return Promise.reject(err);
  }
};

axios.interceptors.response.use(success, error);
