export default {
  state: {
    testes: [],
    testesPerfil: []
  },
  mutations: {
    saveTestes(state, payload) {
      state.testes = payload;
    },
    resetTestesState(state) {
      state.testes = [];
    },
    saveTestesPerfil(state, payload) {
      state.testesPerfil = payload;
    },
    resetTestesPerfilState(state) {
      state.testesPerfil = [];
    }
  }
}
