export default {
  state: {
    cargos: [],
    competencias: [],
    idiomas: [],
    certificacoes: [],
    formacoes: [],
    deptos: []
  },
  mutations: {
    saveCargos(state, payload) {
      state.cargos = payload.cargos;
      state.competencias = payload.competencias;
      state.idiomas = payload.idiomas;
      state.certificacoes = payload.certificacoes;
      state.formacoes = payload.formacoes;
      state.deptos = payload.deptos;
    },
    resetSysInfosState(state) {
      state.cargos = [];
      state.competencias = [];
      state.idiomas = [];
      state.certificacoes = [];
      state.formacoes = [];
      state.deptos = [];
    }
  }
}
