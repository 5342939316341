import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
//import validToken from '../services/middleware';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

/* router.beforeEach((to, from, next) => {
  console.log('antes de cada rota -> ', validToken);

  if (!validToken) {
    localStorage.removeItem(userKey);
    localStorage.removeItem('access_token');
    console.log('verificou o middleware e retornou falso!');
    next({ name: 'Login' });
  } else {
    console.log('verificou o middleware e retornou OK ->>>');
    next();
  }
}); */

export default router;
