export default {
  state: {
    usuarios: []
  },
  mutations: {
    saveUsuarios(state, payload) {
      //console.log('payload usuarios -> ', payload)
      state.usuarios = payload;
    },
    atualizaUsuarios(state, payload) {
      state.usuarios = payload;
    },
    resetUsuariosState(state) {
      state.usuarios = [];
    }
  }
}
