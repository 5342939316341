<template>
  <div class="wrapper">
    <notifications></notifications>
    <div class="main-content">
      <default-navbar
        :type="$route.meta.navbarType"
        :perfilUsuario="perfilUsuario"
        :notificacoesUsuario="notificacoesUsuario"
      ></default-navbar>
      <div>
        <router-view></router-view>
      </div>
      <default-footer v-if="!$route.meta.hideFooter"
      ></default-footer>
    </div>
  </div>
</template>
<script>
import router from '../../routes/router';
import defaultMatchjob from '../../mixins/defaultMatchjob';
import { db } from "../../firebase/config";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { userKey } from "@/global"
/* eslint-disable no-new */
import DefaultNavbar from "./DefaultNavbar.vue";
import DefaultFooter from "./DefaultFooter.vue";

export default {
  mixins: [defaultMatchjob],
  components: {
    DefaultNavbar,
    DefaultFooter,
  },
  data() {
    return {
      perfilUsuario: {
        nm_nome: "",
        hl_foto: "",
      },
      notificacoesUsuario: []
    }
  },
  methods: {
    initUser() {
      const json = localStorage.getItem(userKey);
      if (json === null){
        router.push('/login');
      }
      const userData = JSON.parse(json);
      this.perfilUsuario.nm_nome = userData.nm_nome;
      this.perfilUsuario.hl_foto = userData.hl_foto;
      //console.log('userData ->> ', userData)
    },
    carregaNotificacoes() {
      const colRefNotif =
        "empresas/" +
        this.id.empresa +
        "/usuarios/" +
        this.id.usuario +
        "/notificacoes";
      const resNotif = collection(db, colRefNotif);
      getDocs(resNotif).then((snapshot) => {
        let docs = [];
        snapshot.docs.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        this.notificacoesUsuario = docs;
      });
    },
    removeBackgroundColor() {
      document.body.classList.remove("bg-default");
    },
    async carregaEmpresa() {
      const data = {
        id_empresa: this.id.empresa,
        id_empresa_user: this.id.empresa_user,
        hl_email: this.id.usuario,
        fl_admin: this.id.admin
      }
      if(this.$store.state.sysinfos.cargos.length < 1) {
        this.$store.dispatch('loadSysInfos');
        this.$store.dispatch('loadEmpresa', data);
        //this.$store.dispatch('loadTestesUsuariosEnderecos', data);
      }
    }
  },
  mounted() {
    this.removeBackgroundColor();
    this.initUser();
    this.carregaNotificacoes();
    this.carregaEmpresa();
  },
};
</script>
<style lang="scss"></style>
