<template>
  <router-view></router-view>
</template>

<script>
import { userKey } from "@/global";
export default {
  created() {
    const json = localStorage.getItem(userKey);
    if (json !== null){
      const userData = JSON.parse(json);
      const data = {
        id_empresa: userData.id_empresa,
        id_empresa_user: userData.id_empresa_user,
        hl_email: userData.hl_email,
        fl_admin: userData.fl_admin === "1" ? true : false
      }
      this.$store.dispatch('loadEmpresa', data);
      this.$store.dispatch('loadSysInfos');
    }
  }
}
</script>
