<template>
  <div>
    <base-nav
      v-model="showMenu"
      type="light"
      :transparent="true"
      menu-classes="justify-content-end"
      class="navbar-horizontal navbar-main"
      expand="lg"
    >
      <template v-slot:brand>
        <div class="navbar-wrapper">
          <router-link class="navbar-brand" to="/">
            <h3 class="display-3 mb-0" style="color: white">MATCHJOB</h3>
          </router-link>
        </div>
      </template>

      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <router-link to="/">
              <h3 class="display-3 mb-0">MATCHJOB</h3>
            </router-link>
          </div>
          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              @click="showMenu = false"
            >
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>

      <hr class="d-lg-none" />
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item">
           <a class="nav-link nav-link-icon" href="https://www.facebook.com/matchjobempregos/" target="_blank" rel="noopener" aria-label="Facebook">
             <i class="fab fa-facebook-square"></i>
             <span class="nav-link-inner--text d-lg-none">Facebook</span>
           </a>
         </li>
         <li class="nav-item">
           <a class="nav-link nav-link-icon" href="https://twitter.com/matchjobemprego" target="_blank" rel="noopener" aria-label="Twitter">
             <i class="fab fa-twitter-square"></i>
             <span class="nav-link-inner--text d-lg-none">Twitter</span>
           </a>
         </li>
         <li class="nav-item">
           <a class="nav-link nav-link-icon" href="https://www.linkedin.com/company/matchjob-empregos" target="_blank" rel="noopener" aria-label="LinkedIn">
             <i class="fab fa-linkedin"></i>
             <span class="nav-link-inner--text d-lg-none">LinkedIn</span>
           </a>
         </li>
         <li class="nav-item">
           <a class="nav-link nav-link-icon" href="https://instagram.com/matchjob-empregos" target="_blank" rel="noopener" aria-label="Instagram">
             <i class="fab fa-instagram"></i>
             <span class="nav-link-inner--text d-lg-none">Instagram</span>
           </a>
         </li>
         <li class="nav-item">
           <a class="nav-link nav-link-icon" href="https://www.youtube.com/channel/UCJvVl2D-Jc6ViVKYQ9jGbMg" target="_blank" rel="noopener" aria-label="YouTube">
             <i class="fab fa-youtube"></i>
             <span class="nav-link-inner--text d-lg-none">YouTube</span>
           </a>
         </li>
      </ul>
    </base-nav>

    <div class="main-content">
      <router-view></router-view>
    </div>

    <footer class="py-5" id="footer-main">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div class="col-xl-6">
            <div class="copyright text-center text-lg-left text-muted">
              © {{year}} <a href="https://www.matchjob.com.br" class="font-weight-bold ml-1" target="_blank" rel="noopener">MatchJob Empregos</a>
            </div>
          </div>
          <div class="col-xl-6">
            <ul class="nav nav-footer justify-content-center justify-content-xl-end">
              <li class="nav-item">
                <a href="https://www.matchjob.com.br/ajuda" class="nav-link" target="_blank" rel="noopener">Ajuda</a>
              </li>
              <li class="nav-item">
                <a href="https://www.matchjob.com.br/sobre-nos" class="nav-link" target="_blank" rel="noopener">Sobre Nós</a>
              </li>
              <li class="nav-item">
                <a href="https://blog.matchjob.com.br" class="nav-link" target="_blank" rel="noopener">Blog</a>
              </li>
              <li class="nav-item">
                <a href="https://www.matchjob.com.br/termos-de-uso" class="nav-link" target="_blank" rel="noopener">Termos de Uso</a>
              </li>
              <li class="nav-item">
                <a href="https://www.matchjob.com.br/politica-de-privacidade" class="nav-link" target="_blank" rel="noopener">Política de Privacidade</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page",
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    setBackgroundColor() {
      document.body.classList.add("bg-default");
    },
    removeBackgroundColor() {
      document.body.classList.remove("bg-default");
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
  },
  beforeUnmount() {
    this.removeBackgroundColor();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground();
      },
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>
