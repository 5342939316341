export default {
  state: {
    enderecos: []
  },
  mutations: {
    saveEnderecos(state, payload) {
      state.enderecos = payload;
    },
    atualizaEnderecos(state, payload) {
      state.enderecos = payload;
    },
    resetEnderecoState(state) {
      state.enderecos = [];
    }
  }
}
