import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'

import sysinfos from './modules/sysinfos'
import empresa from './modules/empresa'
import usuarios from './modules/usuarios'
import enderecos from './modules/enderecos'
import testes from './modules/testes'
//import vagas from './modules/vagas'

Vue.use(Vuex)

export default new Vuex.Store({
    actions,
    modules: {
      sysinfos,
      empresa,
      usuarios,
      enderecos,
      testes,
      //vagas
    }
})
