import Vue from 'vue';

export default {
  loadSysInfos({commit}) {
    Vue.prototype.$http.get('sysinfos', )
      .then( res => {
        commit('saveCargos', res.data)
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API.' })
        console.log(err);
      });
  },
  loadEmpresa({commit}, dados) {
    Vue.prototype.$http.post('empresas', dados)
      .then( res => {
        commit('saveEmpresa', res.data)
        commit('saveUsuarios', res.data.usuarios)
        commit('saveTestes', res.data.testes)
        commit('saveTestesPerfil', res.data.testesPerfil)
        commit('saveEnderecos', res.data.enderecos)
        //commit('setVagas', res.data.vagas)
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API.' })
        console.log(err);
      });
  },
  loadTestesUsuariosEnderecos({commit}, dados){
    Vue.prototype.$http.get('novavaga', { params: dados })
    .then( res => {
        //console.log('store loadTestesUsuariosEnderecos -> ', res.data.usuarios)
        commit('saveUsuarios', res.data.usuarios)
        commit('saveTestes', res.data.testes)
        commit('saveTestesPerfil', res.data.testesPerfil)
        commit('saveEnderecos', res.data.enderecos)
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API.' })
        console.log(err);
      });
  },
  loadMensagens({commit}, dados){
    Vue.prototype.$http.get('mensagens/listar', { params: dados })
      .then( res => {
        commit('saveMensagens', res.data)
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API.' })
        console.log(err);
      });
  },
  loadVagas({commit}, dados){
    Vue.prototype.$http.get('vagas', { params: dados })
      .then( res => {
        commit('atualizaVagas', res.data)
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API do Vuex - Actions.' })
        console.log(err);
      });
  },
  reLoadVaga({commit}, dados){
    Vue.prototype.$http.post('vagas/visualizarvaga', dados)
      .then( res => {
        commit('atualizaVagaState', res.data)
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API do Vuex - Actions.' })
        console.log(err);
      });
  },
  loadUsuarios({commit}, dados){
    Vue.prototype.$http.get('usuarios', { params: dados })
      .then( res => {
        commit('saveUsuarios', res.data.usuarios)
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API do Vuex - Actions.' })
        console.log(err);
      });
  },
  loadEnderecos({commit}, dados){
    Vue.prototype.$http.get('enderecos', { params: dados })
      .then( res => {
        commit('saveEnderecos', res.data.enderecos)
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API do Vuex - Actions.' })
        console.log(err);
      });
  },
  loadTestes({commit}, dados){
    Vue.prototype.$http.get('testes/listatestes', { params: dados })
      .then( res => {
        commit('saveTestes', res.data.testes)
      }).catch( err => {
        error({ statusCode: 404, message: 'Falha de comunicação com a API do Vuex - Actions.' })
        console.log(err);
      });
  },
  resetStatesLogoff({commit}) {
    commit('resetEmpresaState');
    commit('resetEnderecoState');
    commit('resetSysInfosState');
    commit('resetTestesState');
    commit('resetUsuariosState');
  },
  atualizaVaga({commit}, vaga) {
    commit('atualizaVagaState', vaga);
  },
  addTimelineVaga({commit}, data) {
    commit('addTimelineVagaState', data);
  },
  alterarStatusVaga({commit}, data) {
    commit('alteraStatusVagaState', data);
  },
  alteraStatusAprovacao({commit}, data) {
    if(data.tipoAprovacao === 'aprovacaoRh') {
      commit('alteraAprovacaoRh', data)
    } else if(data.tipoAprovacao === 'solicitante') {
      commit('alteraAprovacaoSolicitante', data)
    } else if(data.tipoAprovacao === 'outros') {
      commit('alteraAprovacaoOutros', data)
    }
  }
}
