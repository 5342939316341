/*!

=========================================================
* Vue Argon Dashboard PRO - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import ElementUI from 'element-ui';
Vue.use(ElementUI);
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import VueSession from "vue-session";


import "./plugins/axios";
//import { useAppInsights } from './plugins/appinsights'
//useAppInsights().trackEvent({ name: 'App Loaded!' })

// router setup
import router from './routes/router';
// plugin setup
Vue.use(DashboardPlugin);

// Vuex
import store from './store/store';

var options = {
  persist: true
};
Vue.use(VueSession, options);

/* eslint-disable no-new */
new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
});
