export default {
  state: {
    empresa: {},
    plano: {},
    vagas: [],
    contrato: {},
    mensagens: []
  },
  mutations: {
    saveEmpresa(state, payload) {
      state.empresa = payload.empresa;
      state.plano = payload.plano;
      state.vagas = payload.vagas;
      state.contrato = payload.contrato;
      state.mensagens = payload.mensagens;
    },
    saveMensagens(state, payload) {
      state.mensagens = payload.mensagens;
    },
    atualizaVagas(state, payload) {
      state.vagas = payload.vagas;
    },
    resetEmpresaState(state) {
      state.empresa = {};
      state.plano = {};
      state.vagas = [];
      state.contrato = [];
      state.mensagens = [];
    },
    atualizaVagaState(state, payload) {
      var indice = state.vagas.findIndex(i => i.id_processo_vaga === payload.id_processo_vaga);
      state.vagas[indice] = payload;
    },
    addTimelineVagaState(state, payload) {
      var indice = state.vagas.findIndex(i => i.id_processo_vaga === payload.id_processo_vaga);
      state.vagas[indice].json_timeline.push(payload.timeline);
    },
    alteraStatusVagaState(state, payload) {
      var indice = state.vagas.findIndex(i => i.id_processo_vaga === payload.id_processo_vaga);
      state.vagas[indice].fl_status = payload.fl_status;
    },
    alteraAprovacaoRh(state, payload) {
      var indice = state.vagas.findIndex(i => i.id_processo_vaga === payload.id_processo_vaga);
      state.vagas[indice].json_fases[0].aprovacoes.aprovacaoRh.aprovado = payload.status;
    },
    alteraAprovacaoSolicitante(state, payload) {
      var indice = state.vagas.findIndex(i => i.id_processo_vaga === payload.id_processo_vaga);
      state.vagas[indice].json_fases[0].aprovacoes.solicitante.aprovado = payload.status;
    },
    alteraAprovacaoOutros(state, payload) {
      var indice = state.vagas.findIndex(i => i.id_processo_vaga === payload.id_processo_vaga);
      state.vagas[indice].json_fases[0].aprovacoes.outros[payload.posicao].aprovado = payload.status;
    }
  }
}
