<template>
  <footer class="footer px-4 mx-2">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
          <a
            href="https://www.matchjob.com.br"
            class="font-weight-bold ml-1"
            target="_blank"
            >MatchJob Empregos</a
          >
        </div>
      </div>
      <div class="col-lg-6">
        <ul
          class="nav nav-footer justify-content-center justify-content-lg-end"
        >
          <li class="nav-item">
            <a href="https://www.matchjob.com.br/ajuda" class="nav-link" target="_blank">Ajuda</a>
          </li>
          <li class="nav-item">
            <a href="https://www.matchjob.com.br/sobre-nos" class="nav-link" target="_blank">Sobre Nós</a>
          </li>
          <li class="nav-item">
            <a href="https://blog.matchjob.com.br" class="nav-link" target="_blank">Blog</a>
          </li>
          <li class="nav-item">
            <a href="https://www.matchjob.com.br/termos-de-uso" class="nav-link" target="_blank">Termos de Uso</a>
          </li>
          <li class="nav-item">
            <a href="https://www.matchjob.com.br/politica-de-privacidade" class="nav-link" target="_blank">Política de Privacidade</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
